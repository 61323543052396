import CableReady from "cable_ready";
import consumer from "./consumer"

consumer.subscriptions.create("GamePlayerSessionChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        if (data.cableReady) {
            CableReady.perform(data.operations)
            // aqui serve para seção do jogo na mesa basta atualizar a tela
            // var currentLocation = window.location
            // if (currentLocation == '') {
            //     var link = data.operations['morph'][0]['playerUrl'].replace('http://app.purposemining.com.br/', "http://localhost:3001/").replace(/\n/g, "");
                var link = data.operations['morph'][0]['playerUrl'].replace(/\n/g, "");
                // console.log(data.operations['morph'][0]['playerUrl'])
                $.notify({
                    message: 'Facilitador liberou a sessão do jogo!'
                }, {
                    type: 'success',
                    showProgressbar: true,
                    allow_dismiss: true,
                    newest_on_top: true,
                    animate: {
                        enter: 'animated fadeInDown',
                        exit: 'animated fadeOutUp'
                    },
                })
                // location.replace(link)
                location.assign(link)
                app_game_player.forceRerender();

            // }
        }
    }
});
