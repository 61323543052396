import CableReady from "cable_ready";
import consumer from "./consumer"

consumer.subscriptions.create("CardQuestionChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations)
      app_game_player.forceRerender()
      // location.reload();
      change_option()
      $.notify({
        message: 'Facilitador distribuiu as cartas de perguntas com sucesso!'
      }, {
        type: 'success',
        showProgressbar: true,
        allow_dismiss: true,
        newest_on_top: true,
        animate: {
          enter: 'animated fadeInDown',
          exit: 'animated fadeOutUp'
        },
      })

      app_game_player.forceRerender()
    }
  }
});
