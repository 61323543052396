import CableReady from "cable_ready";
import consumer from "./consumer"

consumer.subscriptions.create("GamePlayerTableChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations)

      app_game_player.forceRerender()

      // var link = data.operations['morph'][0]['playerUrl'].replace('http://app.purposemining.com.br/', "http://localhost:3001/").replace(/\n/g, "");
      // console.log(data.operations['morph'][0]['playerUrl'])
      // location.assign(link)
      location.reload();
      change_option()
      $.notify({
        message: 'Facilitador liberou a mesa com sucesso!'
      }, {
        type: 'success',
        showProgressbar: true,
        allow_dismiss: true,
        newest_on_top: true,
        animate: {
          enter: 'animated fadeInDown',
          exit: 'animated fadeOutUp'
        },
      })
      app_game_player.forceRerender()
    }
  }
});
